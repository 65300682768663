/**
 * Function used to get the display text for countdown component for events
 * @param start - start date of event
 * @returns array with days, hours and minutes till event
 */
export const getCountdown = (start: Date) => {
  const now = new Date().getTime();
  const startTimestamp = start.getTime();

  let diff = Math.round((startTimestamp - now) / 1000);

  // days = 86400
  // hours = 3600
  // minutes = 60

  let days = 0;
  let hours = 0;
  let minutes = 0;

  if (diff > 86400) {
    days = Math.floor(diff / 86400);
  }

  diff = diff % 86400;
  if (diff > 3600) {
    hours = Math.floor(diff / 3600);
  }

  diff = diff % 3600;
  if (diff > 60) {
    minutes = Math.floor(diff / 60);
  }

  return [days, hours, minutes];
};
