import React, { useState } from 'react';
import Icons, { IconNames } from '../Icons';
import styled from 'styled-components';
import UnstyledButton from '../UnstyledButton';

type TextInputVariant = 'rounded' | 'cornered';

// this refers to the background of the container the text inputs are in
type TextInputBackground = 'light' | 'dark';

const VARIANTS = {
  rounded: {
    '--border-radius': 999 + 'px',
  },
  cornered: {
    '--border-radius': 12 + 'px',
  },
};

const BACKGROUNDS = {
  dark: {
    '--bg-color': 'var(--color-white)',
    '--text-color': 'var(--color-black-500)',
    '--label-color': 'var(--color-gray-500)',
    '--border-color': 'var(--color-black-500)',
    '--focus-border-color': 'var(--color-primary-500)',
    '--focus-box-shadow': '0 0 6px 2px hsla(var(--color-primary-hue), 0.2)',
  },
  light: {
    '--bg-color': 'transparent',
    '--text-color': 'var(--color-white)',
    '--label-color': 'var(--color-gray-300)',
    '--border-color': 'var(--color-white)',
    '--focus-border-color': 'var(--color-primary-500)',
    '--focus-box-shadow': '0 0 6px 2px hsla(var(--color-primary-hue), 0.2)',
  },
};

const ERRORS = {
  '--text-color': 'var(--color-secondary-500)',
  '--border-color': 'var(--color-secondary-500)',
  '--box-shadow': '0 0 6px 2px hsla(var(--color-secondary-hue), 0.2)',
  '--focus-border-color': 'var(--color-secondary-500)',
  '--focus-box-shadow': '0 0 6px 2px hsla(var(--color-secondary-hue), 0.2)',
};

interface BaseProps {
  type?: 'text' | 'password';
  label: string;
  value: string;
  onChange: (value: string) => void;
  variant: TextInputVariant;
  background: TextInputBackground;
  error?: string;
  dataTest?: string;
  fullWidth?: boolean;
  autoCapitalize?: boolean;
}

interface DefaultProps extends BaseProps {
  icon?: never;
  handleIconClick?: never;
}

interface WithIconProps extends BaseProps {
  icon: IconNames;
  handleIconClick: () => void;
}

type Props = DefaultProps | WithIconProps;

const TextInput = ({
  type = 'text',
  label,
  value,
  onChange,
  variant,
  background,
  error,
  dataTest,
  fullWidth,
  autoCapitalize = true,
  icon,
  handleIconClick,
}: Props) => {
  const variantStyles = VARIANTS[variant];
  const backgroundStyles = BACKGROUNDS[background];

  const [hasError, setHasError] = useState<boolean>(!!error);

  const errorStyles = hasError ? ERRORS : null;

  const wrapperStyles = fullWidth
    ? {
        '--width': '100%',
      }
    : {
        '--width': 'fit-content;',
      };

  const iconColor =
    background === 'light' ? 'var(--color-gray-300)' : 'var(--color-gray-500)';

  return (
    <Wrapper style={wrapperStyles}>
      <TextInputContainer
        aria-invalid={!!error}
        style={{ ...variantStyles, ...backgroundStyles, ...errorStyles }}
      >
        <Input
          data-test={dataTest}
          type={type}
          name={label}
          // this is used to know if input is :valid or not
          required
          value={value}
          onChange={(e) => onChange(e.currentTarget.value)}
          onFocus={() => setHasError(false)}
          autoCapitalize={autoCapitalize ? 'on' : 'off'}
        />

        <Label>
          <Span>{label}</Span>
        </Label>
        {icon ? (
          <IconWrapper>
            <UnstyledButton type="button" onClick={handleIconClick}>
              <Icons id={icon} color={iconColor} />
            </UnstyledButton>
          </IconWrapper>
        ) : null}
      </TextInputContainer>
      {!!error ? (
        <ErrorTextWrap>
          <ErrorText>{error}</ErrorText>
        </ErrorTextWrap>
      ) : null}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: var(--width);
  position: relative;
`;

const TextInputContainer = styled.div`
  --color: var(--color);
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;

  border-radius: var(--border-radius);
  border-width: 2px;
  border-style: solid;
  border-color: var(--border-color);
  box-shadow: var(--box-shadow, none);
  background: var(--bg-color);

  &:focus-within {
    border-color: var(--focus-border-color);
    box-shadow: var(--focus-box-shadow);
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: var(--spacing-16);
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: var(--font-size-body-large-01);
  font-weight: var(--font-weight-medium);
  font-family: 'Epilogue';
  color: var(--label-color);
  text-transform: capitalize;
`;

const Span = styled.span`
  font-family: inherit;
  position: absolute;
  left: 28px;
  transition: 200ms ease;
`;

const Input = styled.input`
  outline: none;
  border: none;
  background: none;
  width: 100%;
  height: 100%;
  padding: 26px 28px 10px;

  font-size: var(--font-size-body-large-01);
  font-weight: var(--font-weight-medium);
  font-family: 'Epilogue';
  color: var(--text-color);

  &:focus + ${Label} ${Span}, &:valid + ${Label} ${Span} {
    transform: translateY(-0.8rem);
    font-size: var(--font-size-body-02);
    font-weight: var(--font-weight-normal);
    letter-spacing: var(--letter-spacing-sm);
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--color-white);
    -webkit-box-shadow: 0 0 0px 1000px var(--color-primary-100) inset;
    box-shadow: 0 0 0px 1000px var(--color-primary-100) inset;
    transition: background-color 5000s ease-in-out 0s;
    background-color: var(--color-primary-100) !important;
    background-clip: content-box !important;
  }

  &:-webkit-autofill
    + ${Label},
    &:-webkit-autofill:hover
    + ${Label},
    &:-webkit-autofill:focus
    + ${Label} {
    color: var(--color-white);
  }

  &:-webkit-autofill
    + ${Label}
    ${Span},
    &:-webkit-autofill:hover
    + ${Label}
    ${Span},
    &:-webkit-autofill:focus
    + ${Label}
    ${Span} {
    transform: translateY(-0.8rem);
    font-size: var(--font-size-body-02);
  }
`;

const ErrorTextWrap = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  padding: 8px 18px;
`;

const ErrorText = styled.p`
  font-size: var(--font-size-body-02);
  font-family: 'Epilogue';
  color: var(--color-secondary-500);
`;

export default TextInput;
