import React, { useState } from 'react';
import styled from 'styled-components';
import {
  SectionTitle,
  DetailText,
  DesktopMediaContainer,
  AspectRatioBox,
  ImageAbsoluteContainer,
  MobileMediaContainer,
  ButtonContainer,
  SessionContainerBase,
  LeftMainContainer,
} from './CreatorLandingPage.styles';
import Button from 'shared/Button';
import { buildImageUrl } from 'cloudinary-build-url';
import Image from 'next/image';
import Icon, { IconNames } from 'shared/Icons';
import { SIGN_UP_SUBSCRIPTION } from 'constants/routes';
import ReactPlayer from 'react-player';
import ShowMoreText from 'components/ShowMoreText';
import { TypeVariant } from 'shared/Typography';

interface Props {
  page: string;
  image: string;
  video: string;
  details: string[];
  hasSubscription: boolean;
  trialPeriod: number;
}

const YourAccessSection = ({
  page,
  image,
  video,
  details,
  hasSubscription,
  trialPeriod,
}: Props) => {
  const [isPlayingDesktop, setIsPlayingDesktop] = useState<boolean>(false);
  const [isPlayingMobile, setIsPlayingMobile] = useState<boolean>(false);

  if (!hasSubscription) {
    return null;
  }

  const blurredUrl = buildImageUrl(image, {
    transformations: {
      effect: {
        name: 'blur',
        value: 1000,
      },
      quality: 1,
    },
  });

  const desktopImageSrc = buildImageUrl(image, {
    transformations: {},
  });

  const mobileImageSrc = buildImageUrl(image, {
    transformations: {},
  });

  return (
    <SectionContainer>
      <LeftMainContainer>
        <SectionTitle>Your Access Includes</SectionTitle>
        <MobileMediaContainer>
          {isPlayingMobile ? (
            <VideoAbsoluteContainer playing={isPlayingMobile}>
              <ReactPlayer
                key="mobile-video"
                url={video}
                playing
                controls
                width="100%"
                height="100%"
                wrapper={VideoWrapper}
                onPause={() => setIsPlayingMobile(false)}
                config={{
                  file: {
                    attributes: {
                      controlsList: 'nodownload',
                    },
                  },
                }}
              />
            </VideoAbsoluteContainer>
          ) : (
            <ImageAbsoluteContainer>
              <Image
                src={mobileImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />

              {video ? (
                <PlayButtonAbsoluteContainer
                  onClick={() => setIsPlayingMobile(true)}
                >
                  <Icon
                    id={IconNames.play}
                    color="var(--color-gray-900)"
                    fill="var(--color-gray-900)"
                    width={20}
                    height={20}
                  />
                </PlayButtonAbsoluteContainer>
              ) : null}
            </ImageAbsoluteContainer>
          )}
        </MobileMediaContainer>
        <DetailsContainer>
          <ShowMoreText
            typeVariant={{
              default: TypeVariant.bodyLarge01,
              phone: TypeVariant.body01,
            }}
            color="var(--color-black-500)"
            characterLimit={300}
            // adding two line breaks (and using white-space: pre-wrap) to cause a line break and then an empty line
            text={details.join('\n\n')}
            title="Your Access Includes"
          />
        </DetailsContainer>
        <ButtonContainer>
          <Button
            variant="fill"
            size="medium"
            link
            href={SIGN_UP_SUBSCRIPTION(page)}
            dataTest="creatorLandingPageSignUpButton"
          >
            {!!trialPeriod ? 'Start Free Trial' : 'Start Today'}
          </Button>
        </ButtonContainer>
      </LeftMainContainer>
      <DesktopMediaContainer>
        <AspectRatioBox>
          <ImageAbsoluteContainer>
            <Image
              src={desktopImageSrc}
              placeholder="blur"
              blurDataURL={blurredUrl}
              layout="fill"
              alt="Creator image"
              objectFit="cover"
            />
            {video ? (
              <PlayButtonAbsoluteContainer
                onClick={() => setIsPlayingDesktop(true)}
              >
                <Icon
                  id={IconNames.play}
                  color="var(--color-gray-900)"
                  fill="var(--color-gray-900)"
                />
              </PlayButtonAbsoluteContainer>
            ) : null}
          </ImageAbsoluteContainer>
          <VideoAbsoluteContainer playing={isPlayingDesktop}>
            <ReactPlayer
              key="desktop-video"
              url={video}
              playing={isPlayingDesktop}
              controls
              width="100%"
              height="100%"
              wrapper={VideoWrapper}
              onPause={() => setIsPlayingDesktop(false)}
              config={{
                file: {
                  attributes: {
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          </VideoAbsoluteContainer>
        </AspectRatioBox>
      </DesktopMediaContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled(SessionContainerBase)`
  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-top: var(--spacing-48);
    padding-bottom: var(--spacing-48);
  }
`;

const PlayButtonAbsoluteContainer = styled.button`
  border: none;
  position: absolute;
  inset: 0;
  width: 60px;
  height: 60px;
  border-radius: 999px;
  background: var(--color-white);

  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* used to visually align icon */
  padding: 0;
  padding-left: 2px;
  cursor: pointer;
  transition: background 200ms;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    width: 40px;
    height: 40px;
  }

  &:hover {
    filter: brightness(0.8);
  }
`;

const VideoAbsoluteContainer = styled(ImageAbsoluteContainer)<{
  playing: boolean;
}>`
  visibility: ${(p) => (p.playing ? 'visible' : 'hidden')};

  @media ${(p) => p.theme.queries.phoneAndDown} {
    visibility: visible;
    display: ${(p) => (p.playing ? 'block' : 'none')};
  }
`;

const VideoWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: var(--color-black-700);
  line-height: 0;
`;

const DetailsContainer = styled.div`
  p {
    padding-bottom: var(--spacing-16);
    padding-top: 0;

    &:last-child {
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    p {
      padding: var(--spacing-16);
      padding-bottom: var(--spacing-24);
      padding-top: 0;

      &:last-child {
        padding-bottom: 0;
        padding-top: 0;
      }

      &:not(:last-child):after {
        content: '';
        display: block;
        width: 80px;
        height: 0px;
        border-bottom: 2px solid var(--color-gray-300);
        padding-top: 20px;
        margin: 0 auto;
      }
    }
  }
`;

export default YourAccessSection;
