import React, { FormEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';
import TextInput from 'shared/TextInput';
import Button from 'shared/Button';
import Typography, { TypeVariant } from 'shared/Typography';
import { useMutation } from 'react-query';
import { addEmailToEmailList } from 'queries/page';
import { IconNames } from 'shared/Icons';

interface TypeProps {
  children: ReactNode | ReactNode[];
  variant: TypeVariant;
  color: string;
}

const HeaderText = ({ children, variant, color }: TypeProps) => (
  <Typography variant={variant} as="h3" color={color} textAlign="center">
    {children}
  </Typography>
);

interface Props {
  size: 'small' | 'large';
  creator: string | undefined;
  page: string;
}

const EmailListForm = ({ size, creator, page }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const variant =
    size === 'small' ? TypeVariant.bodyLarge01Bold : TypeVariant.subtitle01;

  const color =
    size === 'small' ? 'var(--color-white)' : 'var(--color-black-500)';

  const textInputVariant = size === 'small' ? 'light' : 'dark';

  const text = creator ? `${creator}'s` : 'the';

  const handleAddEmailToEmailList = useMutation((emailValue: string) =>
    addEmailToEmailList(emailValue, page),
  );

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    await handleAddEmailToEmailList.mutateAsync(email);
    setEmail('');
    setSubmitting(false);
    setSubmitted(true);
  };

  return (
    <Container onSubmit={(e) => handleSubmit(e)}>
      <HeaderText variant={variant} color={color}>
        Join {text} Email List!
      </HeaderText>
      <Typography
        variant={TypeVariant.body02}
        as="p"
        color={color}
        textAlign={{
          default: 'left',
          phone: 'center',
        }}
      >
        Don't miss upcoming offerings and events
      </Typography>
      <TextInput
        fullWidth
        variant="rounded"
        type="text"
        background={textInputVariant}
        label="Email"
        value={email}
        onChange={(val) => {
          if (submitted) {
            setSubmitted(false);
          }
          setEmail(val);
        }}
        autoCapitalize={false}
      />
      {submitted ? (
        <Button
          fullWidth
          variant="fill"
          size="large"
          disabled={!!!email}
          isLoading={isSubmitting}
          withIcon
          iconLeft
          iconId={IconNames.check}
        >
          Submitted
        </Button>
      ) : (
        <Button
          fullWidth
          variant="fill"
          size="large"
          disabled={!!!email}
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      )}
    </Container>
  );
};

const Container = styled.form`
  background: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-8);
`;

export default EmailListForm;
