import { api, Methods } from 'services/api';
import { CalendarResponse } from '@solin-fitness/types';
import { useQuery } from 'react-query';

export const getWorkoutDropCalendar = async (xPage: string) =>
  await api<CalendarResponse[]>(
    Methods.get,
    '/calendar?weeks_back=0&weeks_forward=2',
    {},
    { xPage },
  );

export const useCalendar = (page: string) =>
  useQuery(['calendar', { page }], () => getWorkoutDropCalendar(page), {
    cacheTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
  });

export const getPublicWorkoutDropCalendar = async (
  xPage: string,
  weeksBack = 0,
  weeksForward = 2,
) =>
  await api<CalendarResponse[]>(
    Methods.get,
    `/public/calendar?weeks_back=${weeksBack}&weeks_forward=${weeksForward}`,
    {},
    { xPage },
  );

export const usePublicCalendar = (
  page: string,
  weeksBack = 1,
  weeksForward = 2,
  select?: (data: CalendarResponse[]) => CalendarResponse[],
) =>
  useQuery(
    ['calendar', { page, public: true }],
    () => getPublicWorkoutDropCalendar(page, weeksBack, weeksForward),
    {
      select,
    },
  );
