/**
 * SectionOrder is used to figure out which sections in the creator landing page
 * will have the image on the right or the left
 * Section Order is a tuple that has 4 items in it, each representing a section
 * The order is the following
 * 1. Your Access
 * 2. Upcoming Sessions
 * 3. Programs & Challenges
 * 4. Learn More
 * 5. Testimonials
 * 6. Email List
 * 7. Footer
 *
 * If a section has value of `true` the image is on the right, `false` the iamge
 * will be on the left
 *
 */
type SectionOrder = [
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
  boolean,
];

export const getSectionOrder = (
  hasSubscriptions: boolean,
  hasUpcomingSessions: boolean,
  hasPrograms: boolean,
  hasTestimonials: boolean,
): SectionOrder => {
  const sectionOrder: SectionOrder = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  if (hasSubscriptions) {
    sectionOrder[0] = true;
    if (hasUpcomingSessions && hasPrograms) {
      sectionOrder[2] = true;
      sectionOrder[4] = true;
    } else if (hasUpcomingSessions && !hasPrograms) {
      sectionOrder[3] = true;
    } else if (!hasUpcomingSessions && hasPrograms) {
      sectionOrder[3] = true;
    } else {
      sectionOrder[4] = true;
    }

    // footer section should match last section rendered
    sectionOrder[6] = hasTestimonials ? sectionOrder[4] : sectionOrder[3];
  } else {
    if (hasUpcomingSessions && !hasPrograms) {
      sectionOrder[1] = true;
      sectionOrder[4] = true;
      sectionOrder[5] = hasTestimonials ? false : true;
    } else if (!hasUpcomingSessions && hasPrograms) {
      sectionOrder[2] = true;
      sectionOrder[4] = true;
      sectionOrder[5] = hasTestimonials ? false : true;
    } else if (hasUpcomingSessions && hasPrograms) {
      sectionOrder[1] = true;
      sectionOrder[3] = true;
    } else {
      sectionOrder[3] = true;
    }

    // footer section should match last section rendered
    sectionOrder[6] = sectionOrder[5];
  }

  return sectionOrder;
};
