import React from 'react';
import Image from 'next/image';
import { buildImageUrl } from 'cloudinary-build-url';
import { RESIZE_TYPES } from '@cld-apis/utils';
import styled from 'styled-components';
import Typography, { TypeVariant } from 'shared/Typography';
import Button from 'shared/Button';
import { SIGN_UP_SUBSCRIPTION } from 'constants/routes';
import EmailListForm from './EmailListForm';
import {
  PurchasedClassesResponse,
  PurchasedEventResponse,
} from '@solin-fitness/types';
import PurchasedSessionBanner from './PurchasedSessionBanner';
import PurchasedSessionBannerMobile from './PurchasedSessionBannerMobile';

interface Props {
  page: string;
  pageId: number;
  logo: string;
  backgroundImage: string;
  description: string;
  hasSubscription: boolean;
  purchasedLiveWorkouts: PurchasedClassesResponse[];
  purchasedEvents: PurchasedEventResponse[];
  trialPeriod: number;
  creator: string | undefined;
}

const CreatorLandingPageBanner = ({
  page,
  pageId,
  logo,
  backgroundImage,
  description,
  hasSubscription,
  purchasedLiveWorkouts,
  purchasedEvents,
  trialPeriod,
  creator,
}: Props) => {
  const blurredUrl = buildImageUrl(backgroundImage, {
    transformations: {
      effect: {
        name: 'blur',
        value: 1000,
      },
      quality: 1,
    },
  });

  const desktopImageSrc = buildImageUrl(backgroundImage, {
    transformations: {
      resize: {
        type: RESIZE_TYPES.SCALE,
        width: 3000,
      },
      quality: 100,
    },
  });

  const mobileImageSrc = buildImageUrl(backgroundImage, {
    transformations: {
      // resize: {
      //   type: RESIZE_TYPES.SCALE,
      //   height: 500,
      // },
      quality: 100,
    },
  });

  const logoSrc = buildImageUrl(logo, {});

  return (
    <Wrapper>
      <MobileAspectRatioBox>
        <Image
          src={mobileImageSrc}
          placeholder="blur"
          blurDataURL={blurredUrl}
          layout="fill"
          objectFit="cover"
          alt="Background picture for creator"
          priority
          quality={100}
        />
        <MobileGradient />
        <PurchasedSessionContainerMobile>
          <PurchasedSessionBannerMobile
            page={page}
            pageId={pageId}
            purchasedLiveWorkouts={purchasedLiveWorkouts}
            purchasedEvents={purchasedEvents}
          />
        </PurchasedSessionContainerMobile>
      </MobileAspectRatioBox>

      <MainContainer>
        <LogoImageContainer>
          <Image
            src={logoSrc}
            layout="responsive"
            width={290}
            height={150}
            alt="Creator's logo"
          />
        </LogoImageContainer>
        {hasSubscription ? (
          <>
            <Typography
              variant={TypeVariant.bodyLarge01}
              color="var(--color-white)"
              as="h4"
              maxLines={4}
              textAlign={{
                default: 'left',
                phone: 'center',
              }}
            >
              {description}
            </Typography>
            <Button
              variant="fill"
              size="large"
              link
              href={SIGN_UP_SUBSCRIPTION(page)}
            >
              {!!trialPeriod ? 'Start Free Trial' : 'Start Today'}
            </Button>
          </>
        ) : (
          <EmailListForm size="small" creator={creator} page={page} />
        )}
      </MainContainer>
      <DesktopImageContainer>
        <Image
          src={desktopImageSrc}
          placeholder="blur"
          blurDataURL={blurredUrl}
          layout="fill"
          objectFit="cover"
          alt="Background picture for creator"
          priority
          quality={100}
        />
        <Gradient />
        <PurchasedSessionContainer>
          <PurchasedSessionBanner
            page={page}
            pageId={pageId}
            purchasedLiveWorkouts={purchasedLiveWorkouts}
            purchasedEvents={purchasedEvents}
          />
        </PurchasedSessionContainer>
      </DesktopImageContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  height: 576px;
  background: var(--color-black-700);
  display: flex;
  gap: var(--spacing-24);
  /* this gets rid of magic spacing below image */
  line-height: 0;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
    height: auto;
    padding-top: 48px;
  }
`;

const MainContainer = styled.div`
  align-self: flex-end;
  flex-shrink: 0;
  margin-left: var(--spacing-64);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: var(--spacing-80);
  width: 330px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    width: 235px;
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    align-self: flex-start;
    isolation: isolate;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    padding: 0 20px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: var(--spacing-48);
    padding-top: var(--spacing-48);
  }
`;

const LogoImageContainer = styled.div`
  width: 290px;
  height: 150px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    width: 220px;
    height: 100px;
  }
`;

const DesktopImageContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

const Gradient = styled.div`
  position: absolute;
  inset: 0;
  width: 20%;
  background-image: linear-gradient(90deg, var(--color-black-700), transparent);
  /* gets rid of a white line that would show left of gradient */
  transform: translateX(-1px);
`;

const MobileGradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 80%;
  bottom: -2px;
  background: linear-gradient(180deg, transparent, var(--color-black-700));
`;

const MobileAspectRatioBox = styled.div`
  display: none;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
    width: 100%;
    padding-top: calc((9 / 16) * 100%);
    position: relative;
  }
`;

const PurchasedSessionContainerMobile = styled.div`
  display: none;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: block;
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const PurchasedSessionContainer = styled.div`
  position: absolute;
  bottom: var(--spacing-48);
  right: 0;
`;

export default CreatorLandingPageBanner;
