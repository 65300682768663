import styled from 'styled-components';
import Button from '../Button';
import Link from 'next/link';
import Portal from '@reach/portal';
import * as Routes from '../../constants/routes';

export const Wrap = styled.div`
  background: #537089;
  padding: 20px 25px;
  border-radius: 40px;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1100px) {
    width: calc(100vw - 40px);
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 15px;
  left: 0;
`;

const Text = styled.p`
  font-family: Brother;
  font-size: 14px;
  line-height: 18px;
  color: white;
`;

const EmailLink = styled.a`
  font-family: Brother;
  font-size: 14px;
  line-height: 18px;
  color: white;
`;

const buttonStyle = {
  backgroundColor: '#F25252',
  borderRadius: 50,
  padding: '8px 25px',
  fontSize: 17,
  fontWeight: 'bold',
  color: 'white',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 200,
  height: 60,
};

const AccountPastDueAlert = () => (
  <Portal>
    <Container>
      <Wrap>
        <Text>
          Your account is past due. Please update your credit card to regain
          access.
          <br />
          Email{' '}
          <EmailLink href="mailto:support@solinfitness.com" target="_blank">
            support@solinfitness.com
          </EmailLink>{' '}
          with any questions.
        </Text>
        <Link href={Routes.SETTINGS} passHref>
          {/* @ts-ignore */}

          <Button style={buttonStyle}>Update card</Button>
        </Link>
      </Wrap>
    </Container>
  </Portal>
);

export default AccountPastDueAlert;
