import Header, { HeaderVariant } from 'shared/Header';
import React from 'react';
import { Footer } from 'components/Footer/Footer';
import styled from 'styled-components';
import { HOME } from '../constants/routes';
import Typography, { TypeVariant } from 'shared/Typography';
import Button from 'shared/Button';

export const Container = styled.div`
  width: 100%;
  background: black;
  min-height: 100vh;
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding-top: 85px;
  }
`;

export const TitleWrap = styled.div`
  margin-bottom: 40px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    margin-bottom: 30px;
    max-width: 280px;
  }
`;

export const TextWrap = styled.div`
  max-width: 800px;
  margin-top: 40px;
  margin-bottom: 75px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding: 0 30px;
    margin-bottom: 10px;
  }
`;

interface ErrorProps {
  status: number;
}

const ErrorPage = ({ status }: ErrorProps) => (
  <div>
    <Header variant={HeaderVariant.dark} />
    <Container>
      <TitleWrap>
        <Typography
          variant={TypeVariant.subtitle01}
          as="p"
          color="var(--color-white)"
          textAlign="center"
        >
          Sorry. Unless you've got a time machine, that content is unavailable.
        </Typography>
      </TitleWrap>
      <div style={{ margin: 20 }}>
        <Button fullWidth variant="outline" size="large" link href={HOME}>
          Browse home page
        </Button>
      </div>

      <TextWrap>
        <Typography
          variant={TypeVariant.bodyLarge01}
          as="p"
          color="var(--color-white)"
          textAlign="center"
        >
          {status} -{' '}
          {status === 500 ? 'Server-side error occurred' : 'Page Not Found'}
        </Typography>
      </TextWrap>
      <div style={{ flex: 1 }} />
      <Footer background="#000" />
    </Container>
  </div>
);

export default ErrorPage;
