import React, { useEffect, useMemo } from 'react';
import { getPage, usePageConfig } from 'queries/creatorPage';
import { useRouter } from 'next/router';
import useUserMetadata from 'hooks/useUserMetadata';
import { hasSubscription as hasSubscriptionFn } from 'services/metadata';
import Head from 'next/head';
import LogRocket from 'logrocket';
import { QueryClient } from 'react-query';
import { GetServerSideProps } from 'next';
import { ParsedUrlQuery } from 'querystring';
import { dehydrate } from 'react-query/hydration';
import dynamic from 'next/dynamic';
import AccountPastDueAlert from '../../components/AcccountPastDueAlert/AcccountPastDueAlert';
import { SubscriptionStatus, UserMetaDataResponse } from '@solin-fitness/types';
import Loading from 'components/Loading/Loading';
import ErrorPage from 'components/ErrorPage';
import Header, { HeaderVariant } from 'shared/Header';
import CreatorLandingPage from 'components/CreatorLandingPage';
import useAuthStore from 'hooks/useAuthStore';

const IS_DEV = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';

const DynamicCreatorSubscribedPage = dynamic(
  () => import('../../components/CreatorPage/SubscribedPage/CreatorSubscribed'),
  { ssr: false },
);

export const getServerSideProps: GetServerSideProps = async (context) => {
  const queryClient = new QueryClient();

  const { creatorPage } = context.params as ParsedUrlQuery;

  const page = creatorPage as string;
  await queryClient.prefetchQuery(['pageConfig', { page: creatorPage }], () =>
    getPage(page),
  );

  return {
    props: { dehydratedState: dehydrate(queryClient) },
  };
};

const CreatorPage = () => {
  const router = useRouter();
  const creatorPage = router.query.creatorPage as string;
  const { isSignedIn, userId } = useAuthStore();

  const {
    isLoading: isLoadingPage,
    data: pageConfig,
    error: pageConfigError,
  } = usePageConfig(creatorPage);

  const { data: metadata, isLoading: isLoadingMetada } = useUserMetadata();

  const [hasSubscription, subscription] = useMemo(
    () =>
      hasSubscriptionFn(
        metadata,
        pageConfig?.config.subscriptions || [],
        isSignedIn,
      ),
    [metadata, pageConfig, isSignedIn],
  );

  const isPastDue = subscription?.status === SubscriptionStatus.pastDue;

  useEffect(() => {
    if (!IS_DEV) {
      // Can we think of something better to do then havin all these NA values?
      // Also, are we even using any of these tools?
      LogRocket.identify(metadata?.email || '', {
        userId: userId || 'NA',
        pageUrl: pageConfig?.url || 'NA',
      });
      LogRocket.getSessionURL((sessionURL) => {
        window.heap?.track('LogRocket', { sessionURL });
      });
      window.heap?.identify(userId ? userId.toString() : 'NA');
    }
  }, []);

  const loggedInAsPageCreator = pageConfig?.users.find(
    (user) => user.id === userId,
  );

  const adminUser = pageConfig?.users.find((u) => u.type === 2);
  const pageTitle = `${
    adminUser
      ? `${adminUser?.profile.firstName} ${adminUser?.profile.lastName}`
      : !pageConfig
      ? 'Error'
      : pageConfig.config.pageName
  } | Solin Stream`;

  if (pageConfigError) {
    return (
      <>
        <Header variant={HeaderVariant.dark} />
        <ErrorPage status={500} />
      </>
    );
  }

  return (
    <div>
      <Head>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} key="title" />
        <meta
          name="og:description"
          content={pageConfig?.config.pageDescription}
        />
        <meta property="og:image" content={pageConfig?.config.creatorImage} />
        <meta
          name="description"
          key="description"
          content={pageConfig?.config.pageDescription}
        />
      </Head>

      <Header variant={HeaderVariant.dark} />

      {isLoadingPage || isLoadingMetada ? (
        <Loading />
      ) : loggedInAsPageCreator || (isSignedIn && hasSubscription) ? (
        <>
          <DynamicCreatorSubscribedPage
            pageConfig={pageConfig!}
            metadata={metadata as UserMetaDataResponse}
          />
        </>
      ) : (
        <CreatorLandingPage config={pageConfig!} metadata={metadata} />
      )}
      {isPastDue && <AccountPastDueAlert />}
    </div>
  );
};

export default CreatorPage;
