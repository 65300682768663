import Button, { ButtonProps } from '@material-ui/core/Button';
import { SECONDARY, PRIMARY } from '../constants/theme';
import { isMobile, isTablet } from 'react-device-detect';

const isMobileOrTablet = isMobile || isTablet;

type Props = ButtonProps & { dataTest?: string };

const ButtonComponent = (props: Props) => {
  const buttonSignUpStyle = {
    boxShadow:
      props.variant !== 'text' && !isMobileOrTablet
        ? '0px 3px 24px rgba(0, 0, 0, 0.35)'
        : 'initial',
    borderRadius: 35,
    color: props.disabled
      ? '#801C1C'
      : props.variant !== 'text'
      ? 'white'
      : PRIMARY,
    border: 'none',
    textTransform: 'capitalize',
    fontFamily: 'Raleway',
    height: 74,
    padding: '25px 0',
    fontSize: 25,
    lineHeight: '16px',
    fontWeight: props.variant !== 'text' ? 500 : 600,
    backgroundColor: props.variant !== 'text' ? SECONDARY : 'initial',
  };

  return (
    <Button
      {...props}
      data-test={props.dataTest}
      variant={props.variant}
      disabled={props.disabled}
      // @ts-ignore
      style={{ ...buttonSignUpStyle, ...props.style }}
    />
  );
};

export default ButtonComponent;

const buttonStyle = {
  marginTop: 12,
  background: isMobileOrTablet ? 'black' : 'white',
  fontFamily: 'Brother',
  fontWeight: 400,
  height: isMobileOrTablet ? 44 : 5,
  border: '1px solid white',
  width: 350,
  overflow: 'hidden',
  boxShadow: 'initial',
};

export const ButtonSignUp = (props: Props) => (
  <ButtonComponent {...props} style={{ ...props.style, ...buttonStyle }} />
);
