import { api, Methods } from '../services/api';
import {
  CommunityPostResponse,
  PostDonationResponse,
  CommunityPostRequest,
  CommunityEditRequest,
  CommentRequest,
} from '@solin-fitness/types';
import { useQuery } from 'react-query';

export interface SendApprovalRequest {
  socialMediaUrl?: string | undefined;
  email?: string | undefined;
}
export const sendCreatorApproval = async (data: SendApprovalRequest) => {
  await api(Methods.post, '/page/approval', data, {});
};

export const addEmailToEmailList = async (email: string, page: string) => {
  await api(
    Methods.post,
    '/page/email',
    {
      email,
    },
    { xPage: page },
  );
};

export const fetchPageCommunityPosts = async (pageId: number) =>
  await api<CommunityPostResponse[]>(Methods.get, `/page/${pageId}/post`);

export const usePageCommunityPosts = (pageId: number) =>
  useQuery(['pagePosts', { pageId }], () => fetchPageCommunityPosts(pageId));

export const fetchPageDonations = async (pageId: number) =>
  await api<PostDonationResponse[]>(Methods.get, `/page/${pageId}/donations`);

export const usePageDonations = (pageId: number) =>
  useQuery(['pageDonations', { pageId }], () => fetchPageDonations(pageId));

export const createCommunityPostPage = async (
  params: CommunityPostRequest,
  pageId: number,
) => await api(Methods.post, `/page/${pageId}/post`, params);

export const editPostPage = async (
  pageId: number,
  postId: number,
  data: CommunityEditRequest,
) => await api(Methods.patch, `/page/${pageId}/post/${postId}`, data);

export const deletePostPage = async (pageId: number, postId: number) =>
  await api(Methods.delete, `/page/${pageId}/post/${postId}`);

export const addPostCommentPage = async (
  params: CommentRequest,
  pageId: number,
  postId: number,
) => await api(Methods.post, `/page/${pageId}/post/${postId}/comment`, params);

export const editPostCommentPage = async (
  pageId: number,
  postId: number,
  commentId: number,
  data: CommunityPostRequest,
) =>
  await api(
    Methods.patch,
    `/page/${pageId}/post/${postId}/comment/${commentId}`,
    data,
  );

export const fetchCommunityPostByIdPage = async (
  postId: number,
  pageId: number,
) =>
  await api<CommunityPostResponse>(
    Methods.get,
    `/page/${pageId}/post/${postId}`,
  );

export const useCommunityPostPage = (postId: number, pageId: number) =>
  useQuery(['communityPostPage', { postId, pageId }], () =>
    fetchCommunityPostByIdPage(postId, pageId),
  );
