import {
  PurchasedClassesResponse,
  PurchasedEventResponse,
} from '@solin-fitness/types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Typography, { TypeVariant } from 'shared/Typography';
import Button from 'shared/Button';
import { DateTime } from 'luxon';
import { UrlObject } from 'url';
import {
  PurchasedSessionBannerProps,
  sortPurchasedItems,
} from './PurchasedSessionBanner';
import Icon, { IconNames } from 'shared/Icons';

declare type Url = string | UrlObject;

const PurchasedSessionBannerMobile = ({
  page,
  pageId,
  purchasedLiveWorkouts,
  purchasedEvents,
}: PurchasedSessionBannerProps) => {
  const purchasedItems = sortPurchasedItems(
    [...purchasedLiveWorkouts, ...purchasedEvents],
    pageId,
  );

  const [index, setIndex] = useState<number>(0);
  const [hasAddedToCalendar, setAddedToCalendar] = useState<boolean>(false);

  if (!purchasedItems.length) {
    return null;
  }

  const handleNext = () => {
    setAddedToCalendar(false);
    setIndex(index + 1);
  };

  const handlePrev = () => {
    setAddedToCalendar(false);
    setIndex(index - 1);
  };

  const handleAddToCalendar = (
    date: Date,
    title: string,
    description: string,
    url: string,
  ) => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: 'AIzaSyBy74nVVToN7VxEyJODMDxRoALrCNhXJNk',
        clientId:
          '72472058751-1klhto82kv34m91b8e4bfnn5o5sc5755.apps.googleusercontent.com',
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        ],
        scope: 'https://www.googleapis.com/auth/calendar.events',
      });
      gapi.client.load('calendar', 'v3');
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: date.toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              if (events[0]?.summary === title) {
                setAddedToCalendar(true);
              } else {
                const event = {
                  summary: title,
                  location: 'https://www.solin.stream/' + url,
                  description,
                  start: {
                    dateTime: date.toISOString(),
                    timeZone: 'America/Los_Angeles',
                  },
                  end: {
                    dateTime: date.toISOString(),
                    timeZone: 'America/Los_Angeles',
                  },
                  reminders: {
                    useDefault: false,
                    overrides: [
                      { method: 'email', minutes: 60 },
                      { method: 'popup', minutes: 10 },
                    ],
                  },
                };

                const request = gapi.client.calendar.events.insert({
                  calendarId: 'primary',
                  resource: event,
                });

                request.execute((eventL: gapi.client.calendar.Event) => {
                  window?.open(eventL.htmlLink);
                  setAddedToCalendar(true);
                });
              }
            });
        });
    });
  };

  const purchasedItem = purchasedItems[index];

  const isLiveWorkout = purchasedItem.hasOwnProperty('liveWorkout')
    ? true
    : false;

  const { id, title, startDate, description } = purchasedItem.hasOwnProperty(
    'liveWorkout',
  )
    ? (purchasedItem as PurchasedClassesResponse).liveWorkout
    : (purchasedItem as PurchasedEventResponse).event;

  const joinHref: Url = isLiveWorkout
    ? {
        pathname: '/[creatorPage]/event/[eventId]/room',
        query: {
          creatorPage: page,
          eventId: id,
        },
      }
    : {
        pathname: '/[creatorPage]/live/[liveWorkoutId]',
        query: {
          creatorPage: page,
          liveWorkoutId: id,
        },
      };

  const date = DateTime.fromJSDate(new Date(startDate)).toFormat('DDDD');
  const time = DateTime.fromJSDate(new Date(startDate)).toFormat('t');

  const dateText = `${date} at ${time.toLowerCase().replace(/\s+/g, '')}`;

  const currentDate = new Date().getTime();
  // joinable if within 5 minutes of the start time
  const isPurchasedItemJoinable =
    new Date(startDate).getTime() - currentDate < 1000 * 60 * 5;

  return (
    <Container>
      <Typography
        variant={TypeVariant.bodyLarge01}
        as="h4"
        color="var(--color-white)"
        maxLines={1}
        textAlign="center"
      >
        {title}
      </Typography>
      <Typography
        variant={TypeVariant.body01Bold}
        as="p"
        color="var(--color-gray-300)"
        textAlign="center"
      >
        {dateText}
      </Typography>
      <ButtonContainer>
        {isPurchasedItemJoinable ? (
          <Button variant="fill" size="small" link href={joinHref}>
            Join Now
          </Button>
        ) : (
          <>
            <Button
              variant="ghost-white"
              size="small"
              onClick={() => undefined}
              withIcon
              iconId={IconNames.check}
            >
              Purchased
            </Button>
            {hasAddedToCalendar ? (
              <Button
                size="small"
                variant="fill"
                disabled
                withIcon
                iconId={IconNames.check}
              >
                Added to Calendar
              </Button>
            ) : (
              <Button
                size="small"
                variant="ghost"
                withIcon
                iconId={IconNames.plus}
                onClick={() =>
                  handleAddToCalendar(
                    new Date(startDate),
                    title,
                    description,
                    page,
                  )
                }
              >
                Add to Calendar
              </Button>
            )}
          </>
        )}
      </ButtonContainer>
      {purchasedItems.length > 1 ? (
        <>
          {index === 0 ? null : (
            <LeftIconContainer onClick={handlePrev}>
              <Icon id={IconNames.chevronLeft} color="var(--color-gray-100)" />
            </LeftIconContainer>
          )}
          {index === purchasedItems.length - 1 ? null : (
            <RightIconContainer onClick={handleNext}>
              <Icon id={IconNames.chevronRight} color="var(--color-gray-100)" />
            </RightIconContainer>
          )}
        </>
      ) : null}
    </Container>
  );
};

const Container = styled.section`
  position: relative;
  width: fit-content;
  margin: 0 var(--spacing-32);
  background: var(--color-black-700-80);
  display: flex;
  flex-direction: column;
  padding: 12px;
  gap: 4px;
  border-radius: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const LeftIconContainer = styled(IconContainer)`
  top: 0;
  left: -32px;
  bottom: 0;
  width: 32px;
`;

const RightIconContainer = styled(IconContainer)`
  top: 0;
  right: -32px;
  bottom: 0;
  width: 32px;
`;

export default PurchasedSessionBannerMobile;
