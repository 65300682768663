import React, { useState } from 'react';
import styled from 'styled-components';
import {
  SectionTitle,
  HeaderText,
  DetailText,
  CaptionText,
  DesktopMediaContainer,
  AspectRatioBox,
  ImageAbsoluteContainer,
  MobileMediaContainer,
  ButtonContainer,
  SessionContainerBase,
  LeftMainContainer,
  RightMainContainer,
  InfoContainer,
  HeaderRow,
  DetailsContainer,
  IconWrapper,
  DetailsRow,
  DetailsWithIconWrap,
  ButtonRow,
  ArrowButton,
  MobileArrowButton,
} from './CreatorLandingPage.styles';
import Button from 'shared/Button';
import { buildImageUrl } from 'cloudinary-build-url';
import Image from 'next/image';
import Icon, { IconNames } from 'shared/Icons';
import {
  ProgramResponse,
  PurchasedProgramResponse,
} from '@solin-fitness/types';
import { SIGN_UP_PROGRAM } from 'constants/routes';
import { hasPurchasedProgram } from 'services/metadata';
import Link from 'next/link';
import { SIGN_UP_SUBSCRIPTION } from 'constants/routes';
import ShowMoreText from 'components/ShowMoreText';
import { TypeVariant } from 'shared/Typography';
import { useQueryClient } from 'react-query';

const getProgramWeeks = (program: ProgramResponse): number => {
  const { sessions } = program;
  if (!!!sessions?.length) {
    return 0;
  }
  return Math.max(...sessions.map((session) => session.week)) + 1;
};

interface Props {
  page: string;
  image: string;
  imageRight?: boolean;
  programs: ProgramResponse[];
  purchasedPrograms: PurchasedProgramResponse[];
  hasSubscription: boolean;
}

const CreatorPrograms = ({
  page,
  image,
  imageRight,
  programs,
  purchasedPrograms,
  hasSubscription,
}: Props) => {
  const [index, setIndex] = useState<number>(0);
  const queryClient = useQueryClient();

  const handlePrevious = () => setIndex(index - 1);
  const handleNext = () => setIndex(index + 1);

  const blurredUrl = buildImageUrl(image, {
    transformations: {
      effect: {
        name: 'blur',
        value: 1000,
      },
      quality: 1,
    },
  });

  const desktopImageSrc = buildImageUrl(image, {
    transformations: {
      quality: 100,
    },
  });

  const mobileImageSrc = buildImageUrl(image, {
    transformations: {
      quality: 100,
      fetchFormat: undefined,
    },
  });

  const program = programs[index];

  const handleSetProgramData = () => {
    queryClient.setQueryData(['programs', { programId: program.id }], program);
  };

  const { id, title, description, amount, isIncludedInMembership } = program;
  const weeks = getProgramWeeks(program);

  const MainComponent = imageRight ? LeftMainContainer : RightMainContainer;
  const containerStyle = imageRight
    ? {}
    : { '--background-color': 'var(--color-off-white-500)' };

  const purchasedProgram = hasPurchasedProgram(purchasedPrograms, id);

  return (
    <SectionContainer style={containerStyle}>
      {imageRight ? null : (
        <DesktopMediaContainer>
          <AspectRatioBox>
            <ImageAbsoluteContainer>
              <Image
                src={desktopImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />
            </ImageAbsoluteContainer>
          </AspectRatioBox>
        </DesktopMediaContainer>
      )}
      <MainComponent>
        <SectionTitle>Programs &amp; Challenges</SectionTitle>
        <MobileMediaContainer>
          <ImageAbsoluteContainer>
            <Image
              src={mobileImageSrc}
              placeholder="blur"
              blurDataURL={blurredUrl}
              layout="fill"
              alt="Creator image"
              objectFit="cover"
            />
          </ImageAbsoluteContainer>
        </MobileMediaContainer>
        <InfoContainer>
          <HeaderRow>
            <MobileArrowButton onClick={handlePrevious} disabled={index === 0}>
              <Icon id={IconNames.chevronLeft} color="var(--chevron-color)" />
            </MobileArrowButton>
            <HeaderText>{title}</HeaderText>
            <MobileArrowButton
              onClick={handleNext}
              disabled={index === programs.length - 1}
            >
              <Icon id={IconNames.chevronRight} color="var(--chevron-color)" />
            </MobileArrowButton>
          </HeaderRow>
          <DetailsContainer>
            <ShowMoreText
              typeVariant={{
                default: TypeVariant.bodyLarge01,
                phone: TypeVariant.body01,
              }}
              color="var(--color-black-500)"
              isHtml
              lines={2}
              text={description}
              title={title}
            />
            <DetailsWithIconWrap>
              <DetailsRow>
                <IconWrapper>
                  <Icon
                    id={IconNames.calendar}
                    color="var(--color-black-700)"
                  />
                </IconWrapper>
                <DetailText>{weeks} Weeks</DetailText>
              </DetailsRow>
              <DetailsRow>
                <IconWrapper>
                  <Icon
                    id={IconNames.dollarSign}
                    color="var(--color-black-700)"
                  />
                </IconWrapper>
                <DetailText>{(amount / 100).toFixed(2)}</DetailText>
              </DetailsRow>
            </DetailsWithIconWrap>
          </DetailsContainer>
        </InfoContainer>
        <ButtonContainer>
          <ButtonRow>
            <ProgramCTAWrap>
              {purchasedProgram ? (
                <>
                  <Button
                    variant="fill"
                    size="medium"
                    disabled
                    withIcon
                    iconId={IconNames.check}
                    onMouseEnter={handleSetProgramData}
                  >
                    Purchased
                  </Button>
                  <Button
                    variant="ghost"
                    size="medium"
                    link
                    onMouseEnter={handleSetProgramData}
                    href={{
                      pathname: '/[creatorPage]/program/[programId]',
                      query: {
                        creatorPage: page,
                        programId: id,
                      },
                    }}
                    withIcon
                    iconId={IconNames.chevronRight}
                  >
                    Access Now
                  </Button>
                </>
              ) : (
                <ButtonWrap>
                  <Button
                    variant="fill"
                    size="medium"
                    link
                    onMouseEnter={handleSetProgramData}
                    href={SIGN_UP_PROGRAM(page, id)}
                  >
                    Purchase Access
                  </Button>
                  <Button
                    variant="ghost"
                    size="medium"
                    link
                    href={{
                      pathname: '/[creatorPage]/program/[programId]',
                      query: {
                        creatorPage: page,
                        programId: id,
                      },
                    }}
                    withIcon
                    iconId={IconNames.chevronRight}
                    onMouseEnter={handleSetProgramData}
                  >
                    Learn More
                  </Button>
                </ButtonWrap>
              )}
            </ProgramCTAWrap>
            <ArrowContainerDesktop>
              <ArrowButton onClick={handlePrevious} disabled={index === 0}>
                <Icon id={IconNames.chevronLeft} color="var(--chevron-color)" />
              </ArrowButton>
              <ArrowButton
                onClick={handleNext}
                disabled={index === programs.length - 1}
              >
                <Icon
                  id={IconNames.chevronRight}
                  color="var(--chevron-color)"
                />
              </ArrowButton>
            </ArrowContainerDesktop>
          </ButtonRow>
          {isIncludedInMembership && hasSubscription ? (
            <Link href={SIGN_UP_SUBSCRIPTION(page)} passHref>
              <div style={{ cursor: 'pointer' }}>
                <CaptionText>Or subscribe for access here</CaptionText>
              </div>
            </Link>
          ) : null}
        </ButtonContainer>
        <ArrowContainerTablet>
          <ArrowButton onClick={handlePrevious} disabled={index === 0}>
            <Icon id={IconNames.chevronLeft} color="var(--chevron-color)" />
          </ArrowButton>
          <ArrowButton
            onClick={handleNext}
            disabled={index === programs.length - 1}
          >
            <Icon id={IconNames.chevronRight} color="var(--chevron-color)" />
          </ArrowButton>
        </ArrowContainerTablet>
      </MainComponent>
      {imageRight ? (
        <DesktopMediaContainer>
          <AspectRatioBox>
            <ImageAbsoluteContainer>
              <Image
                src={desktopImageSrc}
                placeholder="blur"
                blurDataURL={blurredUrl}
                layout="fill"
                alt="Creator image"
                objectFit="cover"
              />
            </ImageAbsoluteContainer>
          </AspectRatioBox>
        </DesktopMediaContainer>
      ) : null}
    </SectionContainer>
  );
};

const SectionContainer = styled(SessionContainerBase)`
  background: var(--background-color);

  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding-top: var(--spacing-48);
    padding-bottom: var(--spacing-48);
  }
`;

const ProgramCTAWrap = styled.div`
  display: flex;
  gap: var(--spacing-4);
`;

const ButtonWrap = styled.div`
  display: flex;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }
`;

const ArrowContainerDesktop = styled.div`
  display: flex;
  gap: 10px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: none;
  }
`;

const ArrowContainerTablet = styled.div`
  display: none;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: flex;
    gap: 10px;
  }

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

export default CreatorPrograms;
