import {
  PurchasedClassesResponse,
  PurchasedEventResponse,
} from '@solin-fitness/types';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Typography, { TypeVariant } from 'shared/Typography';
import { sort } from 'ramda';
import Icon, { IconNames } from 'shared/Icons';
import Button from 'shared/Button';
import { DateTime } from 'luxon';
import { getCountdown } from 'services/events';
import { UrlObject } from 'url';

declare type Url = string | UrlObject;

interface TypeProps {
  children: ReactNode | ReactNode[];
}

const DetailText = ({ children }: TypeProps) => (
  <Typography
    variant={TypeVariant.body01Bold}
    as="p"
    color="var(--color-white)"
  >
    {children}
  </Typography>
);

type PurchasedItem = PurchasedClassesResponse | PurchasedEventResponse;

export const sortPurchasedItems = (items: PurchasedItem[], pageId: number) => {
  const currentDate = new Date();
  const upcomingItems = items.filter((item) => {
    if (item.pageId !== pageId) {
      return false;
    }
    const data = item.hasOwnProperty('liveWorkout')
      ? (item as PurchasedClassesResponse).liveWorkout
      : (item as PurchasedEventResponse).event;
    const length = data.length;
    const startTime = new Date(data.startDate).getTime() + length * 60 * 1000;

    return startTime > currentDate.getTime();
  });

  const sortedItems = sort((a: PurchasedItem, b: PurchasedItem) => {
    const aData = a.hasOwnProperty('liveWorkout')
      ? (a as PurchasedClassesResponse).liveWorkout
      : (a as PurchasedEventResponse).event;
    const bData = b.hasOwnProperty('liveWorkout')
      ? (b as PurchasedClassesResponse).liveWorkout
      : (b as PurchasedEventResponse).event;
    const aDate = new Date(aData.startDate).getTime();
    const bDate = new Date(bData.startDate).getTime();
    return aDate - bDate;
  }, upcomingItems);

  return sortedItems;
};

export interface PurchasedSessionBannerProps {
  page: string;
  pageId: number;
  purchasedLiveWorkouts: PurchasedClassesResponse[];
  purchasedEvents: PurchasedEventResponse[];
}

const PurchasedSessionBanner = ({
  page,
  pageId,
  purchasedLiveWorkouts,
  purchasedEvents,
}: PurchasedSessionBannerProps) => {
  const purchasedItems = sortPurchasedItems(
    [...purchasedLiveWorkouts, ...purchasedEvents],
    pageId,
  );

  const [index, setIndex] = useState<number>(0);
  const [hasAddedToCalendar, setAddedToCalendar] = useState<boolean>(false);

  if (!purchasedItems.length) {
    return null;
  }

  const handleNextClass = () => {
    setAddedToCalendar(false);
    if (index === purchasedItems.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  };

  const handleAddToCalendar = (
    date: Date,
    title: string,
    description: string,
    url: string,
  ) => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: 'AIzaSyBy74nVVToN7VxEyJODMDxRoALrCNhXJNk',
        clientId:
          '72472058751-1klhto82kv34m91b8e4bfnn5o5sc5755.apps.googleusercontent.com',
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
        ],
        scope: 'https://www.googleapis.com/auth/calendar.events',
      });
      gapi.client.load('calendar', 'v3');
      gapi.auth2
        .getAuthInstance()
        .signIn()
        .then(() => {
          gapi.client.calendar.events
            .list({
              calendarId: 'primary',
              timeMin: date.toISOString(),
              showDeleted: false,
              singleEvents: true,
              maxResults: 10,
              orderBy: 'startTime',
            })
            .then((response) => {
              const events = response.result.items;
              if (events[0]?.summary === title) {
                setAddedToCalendar(true);
              } else {
                const event = {
                  summary: title,
                  location: 'https://www.solin.stream/' + url,
                  description,
                  start: {
                    dateTime: date.toISOString(),
                    timeZone: 'America/Los_Angeles',
                  },
                  end: {
                    dateTime: date.toISOString(),
                    timeZone: 'America/Los_Angeles',
                  },
                  reminders: {
                    useDefault: false,
                    overrides: [
                      { method: 'email', minutes: 60 },
                      { method: 'popup', minutes: 10 },
                    ],
                  },
                };

                const request = gapi.client.calendar.events.insert({
                  calendarId: 'primary',
                  resource: event,
                });

                request.execute((eventL: gapi.client.calendar.Event) => {
                  window?.open(eventL.htmlLink);
                  setAddedToCalendar(true);
                });
              }
            });
        });
    });
  };

  const purchasedItem = purchasedItems[index];

  const isLiveWorkout = purchasedItem.hasOwnProperty('liveWorkout')
    ? true
    : false;

  const headerText = isLiveWorkout
    ? 'Drop in Session Purchased'
    : 'Event Purchased';

  const {
    id,
    title,
    startDate,
    length,
    description,
  } = purchasedItem.hasOwnProperty('liveWorkout')
    ? (purchasedItem as PurchasedClassesResponse).liveWorkout
    : (purchasedItem as PurchasedEventResponse).event;

  const joinHref: Url = isLiveWorkout
    ? {
        pathname: '/[creatorPage]/live/[liveWorkoutId]',
        query: {
          creatorPage: page,
          liveWorkoutId: id,
        },
      }
    : {
        pathname: '/[creatorPage]/event/[eventId]/room',
        query: {
          creatorPage: page,
          eventId: id,
        },
      };

  const eventLearnHref = {
    pathname: '/[creatorPage]/event/[eventId]',
    query: {
      creatorPage: page,
      eventId: id,
    },
  };

  const date = DateTime.fromJSDate(new Date(startDate)).toFormat('DDDD');

  const time = `${DateTime.fromJSDate(new Date(startDate)).toFormat(
    't',
  )} - ${DateTime.fromJSDate(new Date(startDate))
    .plus({ minutes: length })
    .toFormat('t')}`;

  const currentDate = new Date().getTime();
  // joinable if within 5 minutes of the start time
  const isPurchasedItemJoinable =
    new Date(startDate).getTime() - currentDate < 1000 * 60 * 5;

  const [days, hours, minutes] = getCountdown(new Date(startDate));

  const hasPurchasableStarted = !days && !hours && !minutes;

  const daysText = days ? `${days} days ` : '';
  const hoursText = hours ? `${hours} hours ` : '';
  const minutesText = minutes ? `${minutes} minutes` : '';

  const countdownText = hasPurchasableStarted
    ? 'Class has started!'
    : `Starting in ${daysText}${hoursText}${minutesText}`;

  return (
    <Container>
      <InfoContainer>
        <IconRow>
          <Typography
            variant={TypeVariant.bodyLarge01Bold}
            as="h3"
            color="var(--color-secondary-100)"
          >
            {headerText}
          </Typography>
          <IconWrapper>
            <Icon id={IconNames.check} color="var(--color-secondary-100)" />
          </IconWrapper>
        </IconRow>
        <DetailText>{title}</DetailText>
        <IconRow>
          <IconWrapper>
            <Icon id={IconNames.calendar} color="var(--color-white)" />
          </IconWrapper>
          <DetailText>{date}</DetailText>
        </IconRow>
        <IconRow>
          <IconWrapper>
            <Icon id={IconNames.clock} color="var(--color-white)" />
          </IconWrapper>
          <DetailText>{time}</DetailText>
        </IconRow>
      </InfoContainer>
      <DetailText>{countdownText}</DetailText>
      <ButtonRowContainer>
        {isPurchasedItemJoinable ? (
          <Button size="small" variant="fill" link href={joinHref}>
            Join Now
          </Button>
        ) : hasAddedToCalendar ? (
          <Button
            size="small"
            variant="fill"
            disabled
            withIcon
            iconId={IconNames.check}
          >
            Added to Calendar
          </Button>
        ) : (
          <Button
            size="small"
            variant="fill"
            withIcon
            iconId={IconNames.plus}
            onClick={() =>
              handleAddToCalendar(new Date(startDate), title, description, page)
            }
          >
            Add to Calendar
          </Button>
        )}
        {purchasedItems.length > 1 ? (
          <Button
            size="small"
            variant="outline"
            withIcon
            iconId={IconNames.chevronRight}
            onClick={handleNextClass}
          >
            See Next Class
          </Button>
        ) : null}
        {!isLiveWorkout && (
          <Button size="small" variant="fill" link href={eventLearnHref}>
            Learn More
          </Button>
        )}
      </ButtonRowContainer>
    </Container>
  );
};

const Container = styled.section`
  background: var(--color-black-700-80);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-24);
  padding-top: 20px;
  padding-left: var(--spacing-24);
  padding-right: var(--spacing-24);
  padding-bottom: 20px;

  @media ${(p) => p.theme.queries.phoneAndDown} {
    display: none;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-12);
`;

const IconRow = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
`;

const IconWrapper = styled.div`
  flex: 0;
  padding-bottom: 2px;
`;

const ButtonRowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-16);
`;

export default PurchasedSessionBanner;
